import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PortalCoreIcon as Icon, Title } from '@falconbrick/fbt-web-components';
import { SelectionBoxOld as SelectionBox } from '@falconbrick/fbt-web-components';
import DialogBox from './DialogBox/DialogBox';
import styles from './ActivityManager.module.css';
import * as activityActions from '../../Actions/ActivityManagerActions';
import ActivityWidget from './ActivityWidget';
import LegendTable from './Legend/LegendTable';
// import SelectionBox from './SelectionBox';
// import cx from "classnames";
// import actData from "./json"; // remove

class ActivityManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startActivityModal: false,
      dialog: null
    };
    this.changeUnitType = this.changeUnitType.bind(this);
    this.changeBlockSelected = this.changeBlockSelected.bind(this);
    this.setPrecedenceMatrix = this.setPrecedenceMatrix.bind(this);
    this.layout = false;
    this.extVH = 0;
  }

  componentWillMount() {
    const { propertySelected, dispatch } = this.props;
    const requestData = { project: propertySelected.id };
    dispatch(activityActions.getInitalData(requestData));
  }

  componentDidMount() {
    document.documentElement.style.overflow = 'hidden';
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    if (JSON.stringify(this.props.propertySelected) !== JSON.stringify(nextProps.propertySelected)) {
      const { propertySelected } = nextProps;
      const requestData = { project: propertySelected.id };
      dispatch(activityActions.getInitalData(requestData));
    }
    if (this.container && this.container.scrollHeight && nextProps.activeUnitType !== this.props.activeUnitType) {
      this.container.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps) {
    const propsToCheck = ['currentData', 'blockSelected', 'activeUnitType'];
    if (propsToCheck.filter(prop => this.props[prop] !== prevProps[prop]).length > 0)
      this.setPrecedenceMatrix();
  }

  componentWillUnmount() {
    document.documentElement.style.overflow = 'scroll';
  }

  setPrecedenceMatrix(i = 0) {
    const { blockSelected, activeUnitType, unitTypes, dispatch } = this.props;
    const context = { unit_type: activeUnitType || unitTypes[i] || 'ALL', ...blockSelected };
    dispatch(activityActions.getSortByPrecedenceFunction(context));
  }

  changeUnitType(i) {
    const { unitTypes, blockSelected, dispatch } = this.props;
    // dispatch(activityActions.changeType(unitTypes[i]));
    this.setPrecedenceMatrix(i);
    dispatch(activityActions.changeType(i, blockSelected));
    setTimeout(() => { dispatch(activityActions.changeType(i, blockSelected)); }, 2000);
  }

  changeBlockSelected(i) {
    const { blockList, dispatch } = this.props;
    const obj = blockList.find(obj => obj.id === i);
    const requestData = {
      // blockSelected: blockList[i],
      blockSelected: obj,
    };
    dispatch(activityActions.changeBlock(requestData));
  }

  selectUnitActivity(id) {
    const { selectedActivityUnits } = this.props;
    const temp = selectedActivityUnits;
    selectedActivityUnits.includes(id) ? temp.pop(id) : temp.push(id);
    this.setState({ selectedActivityUnits: temp });
  }

  openLog() {
    this.props.history.push('/programs/activity-manager-log')
  }

  startActivity() {
    const { selectedActivityUnits } = this.props;
    selectedActivityUnits.length > 0
      ? this.setState({
        startActivityModal: true,
      })
      : null;
  }

  resetActivityWidget() {
    const { dispatch } = this.props;
    dispatch(activityActions.resetActivityWidget());
  }

  modalCancel() {
    this.setState({
      startActivityModal: false,
    });
  }

  modalAction() {
    const { selectedActivityUnits, dispatch } = this.props;
    const requestData = {
      selectedActivityUnits,
      blockSelected: this.props.blockSelected,
      unitTypeSelected: this.props.activeUnitType,
      currentPage: this.props.currentPage,
    };
    dispatch(activityActions.startActivity(requestData));
    this.setState({
      startActivityModal: false,
    });
  }

  createDialog(opts) {
    const { type, data, fn } = opts;
    if (this.props.selectedActivities.length === 0 && this.props.selectedActivityUnits.length === 0)
      return i =>
        fn(i);
    else
      return i => {
        const MyDialog =
          DialogBox({
            type,
            data: { ...data, to: i[name] || i },
            fullscreen: true,
            callback: () => fn(i)
          });
        this.setState({
          dialog: <MyDialog />
        });
      };
  }

  render() {
    const {
      unitTypes,
      gridSize,
      currentPage,
      totalPages,
      grouping,
      currentActivities,
      units,
      currentData,
      selectedActivities,
      selectedActivityUnits,
      activeUnitType,
      blockList,
      blockSelected,
      loading,
      error,
      noResult,
      sortFunction,
      dataTable,
    } = this.props;
    const { dialog, startActivityModal } = this.state;
    let blocks = {};
    let unitTypesSelect = {};
    // let unitTypeSelected = 0;
    // unitTypeSelected =  unitTypes.indexOf(activeUnitType)
    if (blockList.length > 0) {
      blocks = blockList.map(obj => {
        const rObj = {};
        rObj.id = obj.id;
        rObj.name = obj.name;
        return rObj;
      });
    }
    if (unitTypes.length > 0) {
      unitTypesSelect = unitTypes.map(obj => {
        const rObj = {};
        rObj.id = obj;
        rObj.name = obj;
        return rObj;
      });
    }
    return (
      <div className={styles.activity}>
        <div className={styles.titleHeader}>
          <Title titleName="Activity Manager" />
          {/* <ActivityLegend /> */}
        </div>
        <div className={styles.actions}>
          {!!dialog ? dialog : ' '}
          <div className={styles.selectionBar}>
            <div className={styles.selectionBarItems}>
              <SelectionBox
                data={blocks}
                label="Select Tower"
                selected={blockSelected.id}
                callBack={this.createDialog({ type: 'reset', data: { from: blockSelected.id }, fn: this.changeBlockSelected })}
              />
            </div>
            <div className={styles.actionSelection}>
              <SelectionBox
                data={unitTypesSelect}
                label="Unit Type"
                selected={activeUnitType}
                callBack={this.createDialog({ type: 'reset', data: { from: activeUnitType }, fn: this.changeUnitType })}
              />
            </div>
            <div 
            style={{
                float: 'right',
                position: 'fixed',
                right: '1rem',
                top: '4.6rem',
              }}
              // className={styles.btn}
              onClick={() => this.openLog()}
            >
            {
              /*
              <Icon
                name="START_ACTIVITY_BUTTON_ENABLED"
              // customClassName={styles.iconStyle}
              /> */

            }
            {/* <button className={styles.logButton}>Log </button> */}
            </div>
            <div
              style={{
                float: 'right',
                position: 'fixed',
                right: '0rem',
                top: '7rem',
              }}
            >
              <LegendTable />
            </div>
            <div style={{ verticalAlign: 'bottom' }} className={styles.resetDiv}>
              {!loading && (
                <p className={styles.resetBtn} onClick={() => this.resetActivityWidget()}>
                  Clear All Selections
                        </p>
              )}
            </div>
          </div>
          <div style={{ display: 'table-row' }}>
            <div
              className={styles.selectedUnitsCount}
              style={{
                display: 'table-cell',
                paddingRight: 5,
                verticalAlign: 'bottom',
              }}
            >
              {selectedActivityUnits.length} Activities Selected
                  </div>
            <div style={{ display: 'table-cell' }} className={styles.actionBtns}>
              {selectedActivityUnits.length > 0 ? (
                <div
                  // className={styles.btn}
                  onClick={() => this.startActivity()}
                >
                  <Icon
                    name="START_ACTIVITY_BUTTON_ENABLED"
                  // customClassName={styles.iconStyle}
                  />
                </div>
              ) : (
                  <div>
                    <Icon
                      name="START_ACTIVITY_BUTTON_DISABLED"
                    // customClassName={styles.iconStyle}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>

        <div
          className={error ? styles.activityWrapperError : styles.activityWrapper}
          ref={c => {
            this.container = c;
          }}
        >
          {error ? (
            <div className={styles.errorMsg}>
              <p style={{ textAlign: 'center' }}>
              <span style={{lineHeight:"35px"}}><strong>Please select a tower from the drop down above.</strong></span><br/>
              <span>If you are still seeing this message.</span><br/><span>please check your internet connection</span><br/><span> and try again.{' '}</span>
              </p>
            </div>
          ) : loading ? (
            <p className={styles.loadingMsg}> {this.props.loadingMsg}</p>
          ) : (
                <ActivityWidget
                  gridSize={gridSize}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  grouping={grouping}
                  currentActivities={currentActivities}
                  units={units}
                  currentData={currentData}
                  selectedActivities={selectedActivities}
                  selectedActivityUnits={selectedActivityUnits}
                  loading={loading}
                  noResult={noResult}
                  sortFunction={sortFunction}
                  dataTable={dataTable}
                />
              )}
        </div>
        {startActivityModal && (
          <div className={styles.overlay}>
            <div className={styles.overlayMain}>
              <div className={styles.overlayCancel} onClick={() => this.modalCancel()} role="button" tabIndex={0}>
                Back
                      </div>
              <div className={styles.overlayContent}>
                Would you like to start {selectedActivityUnits.length} activities?
                      </div>
              <div className={styles.overlayAction} onClick={() => this.modalAction()} role="button" tabIndex={0}>
                Proceed
                      </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { activityManager, property, blockInfo } = state;
  return {
    activityList: activityManager.activityList,
    activityTable: activityManager.activityTable,
    unitTypes: activityManager.unitTypes,
    activeUnitType: activityManager.activeUnitType,
    selectedActivityUnits: activityManager.selectedActivityUnits,
    dataList: activityManager.dataList,
    dataTable: activityManager.dataTable,
    gridSize: activityManager.gridSize,
    totalPages: activityManager.totalPages,
    totalActivities: activityManager.totalActivities,
    currentPage: activityManager.currentPage,
    currentActivities: activityManager.currentActivities,
    currentData: activityManager.currentData,
    searchedActivities: activityManager.searchedActivities,
    grouping: activityManager.grouping,
    units: activityManager.units,
    selectedActivities: activityManager.selectedActivities,
    propertySelected: property.propertySelected,
    blockInfo,
    blockSelected: blockInfo.blockSelected,
    blockList: blockInfo.blockList,
    loading: activityManager.loading,
    loadingMsg: activityManager.loadingMsg,
    error: activityManager.error || blockInfo.error,
    noResult: activityManager.noResult,
    sortFunction: activityManager.sortFunction,
  };
};
export default connect(mapStateToProps)(ActivityManager);
